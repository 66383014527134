import React from "react";
import Article from "../../utils/Article";
import loadStates from "../../../hocs/loadStates";

function ArticlesContainer({ articles, categoryFilter, location }) {
  const getFilteredCategory = () => {
    return categoryFilter.toFilter;
  };

  const filterArticles = (category, articles) => {
    return articles.filter(article =>
      article.node.frontmatter.category.includes(category)
    );
  };

  const curCat = getFilteredCategory();
  const filteredArtciles = curCat === "all" ? articles : filterArticles(curCat, articles);

  return (
    <>
      <div className={`flex flex-wrap pb-40 -m-3p`}>
        {filteredArtciles.map((article, i) => (
          <Article
            key={article.node.frontmatter.title}
            type="normal"
            order={i}
            location={location}
            {...article}
          />
        ))}
      </div>
    </>
  );
}

export default loadStates(ArticlesContainer);
