// extracted by mini-css-extract-plugin
export var article = "index-module--article--wpaVp";
export var author = "index-module--author--9RhxT";
export var bg = "index-module--bg--QaGvN";
export var bgContainer = "index-module--bgContainer--MSXlE";
export var blue = "index-module--blue--7QSJq";
export var category = "index-module--category--s1Ivk";
export var categoryStrip = "index-module--categoryStrip--BH+Sn";
export var clamp = "index-module--clamp--cJVBV";
export var content = "index-module--content--tKHul";
export var contentHeader = "index-module--contentHeader--DOd2C";
export var contentWrapper = "index-module--contentWrapper--W-ae8";
export var green = "index-module--green--OE7P4";
export var hero = "index-module--hero--GxS0C";
export var logo = "index-module--logo--WcfYg";
export var normal = "index-module--normal--H7jnW";
export var title = "index-module--title--H8efB";
export var vectrViews = "index-module--vectrViews--+RffB";
export var vvBg = "index-module--vvBg--IGJuC";
export var yellow = "index-module--yellow--iEQNt";