import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import News from "../components/News";

let IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <SEO title="News / Media" />
    <News
      articles={data.news.edges}
      featuredNews={data.featuredNews.edges}
      newsCategories={data.newsCategories.edges}
      location={location}
    />
  </Layout>
);

export const query = graphql`query NewsImgQuery {
  newsCategories: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "news-category"}}}
    sort: {fields: frontmatter___order, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          name
          description
          title
          order
        }
      }
    }
  }
  featuredNews: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "news"}, featured: {eq: true}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          category
          link
          title
          sector_focus
          author
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          feature_image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          internal_page
        }
      }
    }
  }
  news: allMarkdownRemark(
    filter: {frontmatter: {type: {eq: "news"}, featured: {eq: false}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          category
          link
          title
          sector_focus
          author
          date
          content
          thumbnail {
            childImageSharp {
              gatsbyImageData(quality: 50, layout: FULL_WIDTH)
            }
          }
          internal_page
        }
      }
    }
  }
}
`;

export default IndexPage;
