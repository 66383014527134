import React, { useEffect, useRef } from 'react';
import * as styles from './index.module.scss';
import PageTitle from '../utils/PageTitle';
import NextSectionButton from '../utils/Button/NextSectionButton';
import Article from '../utils/Article';
import ArticlesContainer from './ArticlesContainer';
import Filter from '../utils/Filter';
import Slider from 'react-slick';
import { TweenMax, Sine } from 'gsap';
import loadStates from '../../hocs/loadStates';

function News({ images, featuredNews, articles, location, newsCategories }) {

	const categoryNamesArray = articles.map(article => article.node.frontmatter.category).flat()
	const categoriesWithArticles = [...new Set(categoryNamesArray)]
	const categoriesWithArticlesMapped = newsCategories.filter(category => categoriesWithArticles.includes(category.node.frontmatter.name))
	const catNames = categoriesWithArticlesMapped.map(c => {
		return {
			name: c.node.frontmatter.name,
			displayName: c.node.frontmatter.title
		} 
	});
	catNames.unshift({name: 'all', displayName: "All"})
	const catNamesWithAll = catNames

	const filter = useRef();
	const container = useRef();
	const scrollToBtn = useRef();
	useEffect(() => {
    window.addEventListener('scroll', fixCatFilterOnScroll, true);
    
    const newsContainer = container.current;
		const btn = scrollToBtn.current;

		displayScrollToBtn(newsContainer, btn);

		return () => {
			window.removeEventListener('scroll', fixCatFilterOnScroll, true);
		};
	});

	const displayScrollToBtn = (container, btn) => {
		if (container.getBoundingClientRect().top < window.innerHeight * 0.8) {
			TweenMax.to(btn, 0.5, {
				autoAlpha: 0,
				ease: Sine.easeOut
			});
		} else {
			TweenMax.to(btn, 0.5, {
				autoAlpha: 1,
				ease: Sine.easeOut
			});
		}
	};

	const fixCatFilterOnScroll = () => {
		const catFilter = filter.current;
		const newsContainer = container.current;
		const btn = scrollToBtn.current;

		displayScrollToBtn(newsContainer, btn);

		if (
			newsContainer.getBoundingClientRect().top <=
			catFilter.getBoundingClientRect().top
		) {
			TweenMax.to(filter.current, 0.5, { autoAlpha: 1, ease: Sine.easeOut });
		} else {
			TweenMax.to(filter.current, 0.2, { autoAlpha: 0, ease: Sine.easeOut });
		}
	};
	const settings = {
		infinite: true,
		slidesToShow: 1,
		ease: 'easeOut',
		autoPlay: 3000,
		arrow: false,
		autoplay: false,
		dots: true,
		swipe: false,
		speed: 700,
		draggable: false,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1370,
				settings: {
					swipe: true
				}
			},
			{
				breakpoint: 1360,
				settings: {
					swipe: false
				}
			},
			{
				breakpoint: 1025,
				settings: {
					swipe: true
				}
			},
			{
				breakpoint: 550,
				settings: {
					swipe: true
				}
			}
		]
	};
	return (
		<>
			<section className={`page-section-top relative overflow-hidden`}>
				<div
					className={`${styles.featuredArticleContainer} section-container featuredArticles`}
				>
					<PageTitle title="News / Media" color="red" />
					<Slider {...settings}>
						{featuredNews.map((featured, i) => (
							<div key={i}>
								<Article type="hero" {...featured} location={location} />
							</div>
						))}
					</Slider>
				</div>
				<div
					className={`${styles.btnContainer} absolute lg:hidden`}
					ref={scrollToBtn}
				>
					<NextSectionButton scrollTo="#newsContainer" />
				</div>
			</section>
			<div
				className={`filter-container pl-0 fixed z-10 opacity-0 invisible`}
				id="catFilter"
				ref={filter}
			>
				<Filter categories={catNamesWithAll} />
			</div>
			<section
				className={`${styles.sectionArticles}  bg-brand-grey pt-20 pb-40 relative`}
			>
				<div
					className={`${styles.container} section-container section-container--withFilter`}
					id="newsContainer"
					ref={container}
				>
					<ArticlesContainer
						images={images}
						articles={articles}
						location={location}
					/>
				</div>
			</section>
		</>
	);
}

export default loadStates(News);
