import React, { Component } from "react";
import * as styles from "./index.module.scss";
import { GatsbyImage } from "gatsby-plugin-image";
import ReadMoreButton from "../Button/ReadMoreButton";
import Logo from "../Logo";
import { connect } from "react-redux";
import { setModalContent, toggleModal } from "../../../state/actions";
import { TweenMax, Sine } from "gsap";
import { Link } from "gatsby";

class Article extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.bg = null;
    this.content = null;
    this.tl = null;
    this.article = null;
    this.normalHeight = 0;
    this.state = {
      articleHeight: 0,
      linkAttr: {
        link: "",
        target: "",
        ref: "",
      },
    };
  }
  componentDidMount() {
    const { type } = this.props;

    this.setArticleHeight();

    if (type === "normal") {
      TweenMax.to(this.article, 1.5, {
        autoAlpha: 1,
        ease: Sine.easeOut,
      }).delay(this.props.order * 0.1);
    }

    if (this.article) {
      window.addEventListener("resize", this.setArticleHeight, false);
    }
  }

  componentDidUpdate() {
    const { type } = this.props;

    if (type === "normal") {
      TweenMax.fromTo(
        this.article,
        1,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          ease: Sine.easeOut,
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.article) {
      window.removeEventListener("resize", this.setArticleHeight, false);
    }
  }

  setArticleHeight = () => {
    if (
      this.article &&
      typeof window !== "undefined" &&
      window.innerWidth >= 768
    ) {
      const height = this.article.clientWidth * 1.3;
      this.setState({
        articleHeight: height,
      });
    }
  };

  render() {
    const { type, node } = this.props;
    const { internal_page, link, category } = this.props.node.frontmatter;

    const vectrViews = category.includes("vectrviews") ? styles.vectrViews : "";
    const clampTitle = category.includes("vectrviews") ? styles.clamp : null;
    const stripColor = category.includes("news")
      ? styles.yellow
      : category.includes("vectrviews")
      ? styles.green
      : styles.green;

    return (
      <>
        {type === "hero" ? (
          <article
            className={`${styles.article} ${styles.hero} ${styles.vectrViews} ${clampTitle} relative w-full overflow-hidden my-32`}
          >
            {internal_page ? (
              <Link to={`${node.fields.slug}`}>
                <FeaturedArticleContent
                  {...this.props.node.frontmatter}
                  vectrViews={vectrViews}
                  stripColor={stripColor}
                />
              </Link>
            ) : (
              <a href={link}>
                <FeaturedArticleContent
                  {...this.props.node.frontmatter}
                  vectrViews={vectrViews}
                  stripColor={stripColor}
                />
              </a>
            )}
          </article>
        ) : (
          <article
            className={`${styles.article} ${styles.normal} ${vectrViews} relative w-full overflow-hidden`}
            ref={(article) => (this.article = article)}
            style={{ height: this.state.articleHeight }}
          >
            {internal_page ? (
              <Link to={`${node.fields.slug}`}>
                <ArticleContent
                  {...this.props.node.frontmatter}
                  vectrViews={vectrViews}
                  stripColor={stripColor}
                />
              </Link>
            ) : (
              <a href={link}>
                <ArticleContent
                  {...this.props.node.frontmatter}
                  vectrViews={vectrViews}
                  stripColor={stripColor}
                />
              </a>
            )}
          </article>
        )}
      </>
    );
  }
}

Article.defaultProps = {
  img: "",
  title: "",
  text: "",
  category: "",
  type: "normal",
};

function FeaturedArticleContent({
  vectrViews,
  stripColor,
  sector_focus,
  author,
  title,
  category,
  feature_image,
  thumbnail,
}) {
  return (
    <>
      <div className={`${styles.bgContainer}`}>
        {feature_image ? (
          // <BackgroundImage
          //   Tag="div"
          //   className={`${styles.bg} overflow-hidden w-full h-full`}
          //   fluid={feature_image.childImageSharp.gatsbyImageData}
          //   backgroundColor={`#040e18`}
          // />
          <GatsbyImage
            className={`${styles.bg} overflow-hidden w-full h-full`}
            image={feature_image.childImageSharp.gatsbyImageData}
            loading="eager"
            alt=""
          />
        ) : thumbnail ? (
          // <BackgroundImage
          //   Tag="div"
          //   className={`${styles.bg} overflow-hidden w-full h-full`}
          //   fluid={thumbnail.childImageSharp.gatsbyImageData}
          //   backgroundColor={`#040e18`}
          // />
          <GatsbyImage
            className={`${styles.bg} overflow-hidden w-full h-full`}
            image={thumbnail.childImageSharp.gatsbyImageData}
            loading="eager"
            alt=""
          />
        ) : null}
      </div>
      <div
        className={`${styles.contentWrapper} right-0 overflow-hidden bg-brand-white brand-white z-10`}
      >
        <div
          className={`${styles.categoryStrip} ${stripColor} right-0 h-full w-8`}
        >
          <span className={styles.category}>
            {category.includes("vectrviews") ? "vectr views" : category}
          </span>
        </div>
        <div className={`${styles.content} p-12 xxl:p-20`}>
          {vectrViews && (
            <div className={`${styles.contentHeader} font-size-captions`}>
              Sector Focus
              <div className={styles.sectorFocus}>{sector_focus}</div>
            </div>
          )}
          <h5 className={`${styles.title} title title-md`}>{title}</h5>
          {vectrViews && (
            <div className={`${styles.author} font-size-small-copy my-8`}>
              Vectr Research - {author}
            </div>
          )}
          <div className="absolute bottom-0 left-0 mb-12 ml-12 xxl:ml-20 xxl:mb-20">
            <ReadMoreButton type="primary" color="white">
              Read More
            </ReadMoreButton>
          </div>
        </div>
      </div>
    </>
  );
}

function ArticleContent({
  vectrViews,
  stripColor,
  sector_focus,
  author,
  title,
  category,
  thumbnail,
}) {
  return (
    <>
      <div className={styles.bgContainer}>
        {thumbnail ? (
          // <BackgroundImage
          //   Tag="div"
          //   className={styles.bg}
          //   fluid={thumbnail.childImageSharp.gatsbyImageData}
          //   backgroundColor={`#040e18`}
          // />
          <GatsbyImage
            className={`${styles.bg} w-full h-full`}
            image={thumbnail.childImageSharp.gatsbyImageData}
            loading="eager"
          />
        ) : null}
        {vectrViews ? (
          <div className={styles.vvBg}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={`${styles.contentHeader} font-size-captions`}>
              Sector Focus
              <div className={styles.sectorFocus}>{sector_focus}</div>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={`${styles.contentWrapper} right-0 overflow-hidden bg-brand-white z-10`}
      >
        <div
          className={`${styles.categoryStrip} ${stripColor} right-0 h-full w-8`}
        >
          <span className={styles.category}>
            {category.includes("vectrviews") ? "vectr views" : category}
          </span>
        </div>
        <div
          className={`${styles.content} relative p-6 sm:pl-0 sm:pt-0 sm:pr-0 xxl:p-10 md:pb-0`}
        >
          <h5 className={`${styles.title} title title-xs sm:mx-10 sm:m-10`}>
            {title}
          </h5>
          {vectrViews && (
            <div
              className={`${styles.author} absolute bottom-0 left-0 pl-6 pb-6 xxl:pl-10 xxl:pb-10 md:relative md:pl-0 md:pb-0 w-full font-size-captions mt-4 sm:mx-10 sm:mt-0 sm:mb-5`}
            >
              Vectr Research -<span className="block">{author}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateContent: (content) => dispatch(setModalContent(content)),
    openModal: (isOpenned) => dispatch(toggleModal(isOpenned)),
  };
};

export default connect(null, mapDispatchToProps)(Article);
